import { useContext, useLayoutEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import {
  AUTOLOGIN,
  CLEAR_DEVICES,
  CREATEUSER,
  HIDE_QUESTION,
  LOGIN,
  REGISTER_DEVICE,
  REMOVEUSER,
  RESET_PASSWORD,
  RESOLVE_TOKEN,
  UPDATEUSER,
  UPDATEUSERPROFILE
} from './mutations'
import { useAuth } from '../../hooks/useAuth'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { AUTH_KEY, BRANCH_KEY } from '../../constants/storageKeys'
import { type IUser } from '../../types/user'
import { AuthContext } from '../../contexts/AuthContext'
import { GETACTIVEUSERS } from './queries'
import { sortByKey } from '../../utils/sortByKey'

interface LoginInput {
  email: string
  password: string
}

interface GetUsersResponse {
  getActiveUsers: IUser[]
}

export const useLogin = () => {
  const { setItem } = useLocalStorage()
  const { user, login } = useAuth()

  const [action, { data, loading, error }] = useMutation(LOGIN)

  const doLogin = async ({ email, password }: LoginInput) => {
    await action({ variables: { input: { email: email.toLowerCase(), password } } })
  }

  useLayoutEffect(() => {
    login(data?.login)
    if (data?.login?.authToken) {
      setItem(AUTH_KEY, data.login.authToken)
    }
  }, [data])

  return {
    doLogin, user, loading, error
  }
}

export const useAutoLogin = () => {
  const { setItem } = useLocalStorage()
  const [action, { data, loading, error }] = useMutation(AUTOLOGIN)
  const { setUser } = useContext(AuthContext)

  const doAutologin = async (token: string) => {
    if (token) {
      await action({
        variables: { input: { authToken: token } },
        fetchPolicy: 'no-cache'
      })
    }
  }

  useLayoutEffect(() => {
    if (data?.autologin?.authToken) {
      setItem(AUTH_KEY, data.autologin.authToken)
      setUser(data.autologin)
    }
  }, [data])

  return { doAutologin, loading, error }
}

export const logout = () => {
  localStorage.setItem(AUTH_KEY, '')
  localStorage.removeItem(BRANCH_KEY)
  window.location.reload()
}

export const useUpdateUser = () => {
  const [action, { loading }] = useMutation(UPDATEUSER)
  const { user } = useContext(AuthContext)

  const updateUser = async ({ _id, name, DNI, avatar, phone, email, role, street, password, temarySection, testSection }: IUser) => {
    await action({
      variables: {
        input: {
          _id,
          email,
          name,
          DNI,
          avatar,
          phone,
          street,
          password,
          role: role?._id,
          executedBy: user?.email,
          testSection,
          temarySection
        }
      }
    })
  }

  return { updateUser, loading }
}

export const useUpdateUserProfile = () => {
  const [action, { loading }] = useMutation(UPDATEUSERPROFILE)

  const updateUserProfile = async ({ name, DNI, avatar, phone, email, street }: IUser) => {
    await action({
      variables: {
        input: {
          email,
          name,
          DNI,
          avatar,
          phone,
          street
        }
      }
    })
  }

  return { updateUserProfile, loading }
}

export const useCreateUser = () => {
  const [action, { loading }] = useMutation(CREATEUSER)
  const { user } = useContext(AuthContext)

  const createUser = async ({ _id, name, DNI, phone, email, password, role, street, temarySection, testSection }: IUser) => {
    await action({
      variables: {
        input: {
          _id,
          email,
          name,
          DNI,
          phone,
          street,
          password,
          role: role?._id,
          executedBy: user?.email,
          testSection,
          temarySection
        }
      }
    })
  }

  return { createUser, loading }
}

export const useRemoveUser = () => {
  const [action, { loading }] = useMutation(REMOVEUSER)

  const removeUser = async (input: string) => {
    await action({
      variables: {
        input
      }
    })
  }

  return {
    removeUser,
    loading
  }
}

export const useGetActiveUsers = () => {
  const { data, loading, refetch } = useQuery<GetUsersResponse>(GETACTIVEUSERS, { fetchPolicy: 'no-cache' })
  return {
    users: sortByKey((data?.getActiveUsers || []), 'name'),
    loading,
    refetch
  }
}

interface TokenResponse {
  resolveToken: any
}

export const useResolveToken = () => {
  const [action, { loading, error }] = useMutation<TokenResponse>(RESOLVE_TOKEN)

  const resolveToken = async (input: string) => {
    const token = await action({ variables: { input } })

    return token.data?.resolveToken
  }

  return { resolveToken, loading, error }
}

export const useResetPassword = () => {
  const [action, { loading, error }] = useMutation(RESET_PASSWORD, {
    fetchPolicy: 'no-cache'
  })

  const resetPassword = async (email: string) => {
    const data = await action({
      variables: { input: email },
      fetchPolicy: 'no-cache'
    })

    return data as IUser
  }

  return { resetPassword, loading, error }
}

export const useRegisterDevice = () => {
  const [action, { loading, error }] = useMutation(REGISTER_DEVICE)
  const { user } = useAuth()

  const registerDevice = async (device: string) => {
    const dev = await action({
      variables: {
        input: {
          user: user?._id,
          device
        }
      }
    })

    return dev.data?.registerDevice
  }

  return { registerDevice, loading, error }
}

export const useHideQuestion = () => {
  const [action, { loading, error }] = useMutation(HIDE_QUESTION)
  const { user } = useAuth()

  const hideQuestion = async (question: string) => {
    const dev = await action({
      variables: {
        input: {
          user: user?._id,
          question
        }
      }
    })

    return dev.data?.addHiddenQuestion
  }

  return { hideQuestion, loading, error }
}

export const useClearDevices = () => {
  const [action, { loading, error }] = useMutation(CLEAR_DEVICES)

  const clearDevices = async (user: IUser) => {
    const response = await action({
      variables: {
        input: user?._id
      }
    })

    return response.data?.clearUserDevices
  }

  return { clearDevices, loading, error }
}
