import React, { useEffect, useMemo, useState, type FC } from 'react'
import { useGetQuestionsByTopic } from '../../../../services/question'
import { Modal, Spin } from 'antd'
import { type ITopic } from '../../../../types/topic'
import { useSocket } from '../../../../hooks/useSocket'
import { type IQuestion } from '../../../../types/question'
import { IconCheck, IconCross } from '@tabler/icons-react'

import './CopyProgressModal.styles.scss'

interface CopyProgressModalProps {
    topicName: string
    topicToCopy?: ITopic
    visible: boolean
    onClose: () => void
    onCopy: () => void
}

const CopyProgressModal: FC<CopyProgressModalProps> = ({ onCopy, onClose, topicName, topicToCopy, visible }) => {
    const { questions, loading } = useGetQuestionsByTopic(visible ? topicToCopy?._id : undefined)

    const socket = useSocket()
    const isConnected = socket?.connected

    const [fullfilledIds, setFullfilledIds] = useState<string[]>([])
    const [errorIds, setErrorIds] = useState<string[]>([])

    const [copying, setCopiying] = useState(false)
    const [finished, setFinished] = useState(false)

    const handleCopy = () => {
        setCopiying(true)
        onCopy()
    }

    const handleClose = () => {
        onClose()
        setCopiying(false)
        setFinished(false)
        setFullfilledIds([])
        setErrorIds([])
    }

    useEffect(() => {
        if (visible) {
            if (!isConnected) {
                socket?.connect()
            } else {
                socket.emit('copy-socket')
            }
        }
    }, [isConnected, socket, visible])

    useEffect(() => {
        socket?.on('socket-progress', (mess) => {
            if (mess.type === 'success') {
                setFullfilledIds((prev) => ([...prev, mess.question]))
            } else if (mess.type === 'error') {
                setErrorIds((prev) => ([...prev, mess.question]))
            } else if (mess.type === 'progress-end') {
                setCopiying(false)
                setFinished(true)
            }
        })
    }, [socket])

    const okText = useMemo(() => {
        if (finished) return 'Cerrar'
        if (copying) return undefined

        return 'Empezar'
    }, [finished, copying])

    return (
        <Modal
            open={visible}
            onCancel={finished ? undefined : handleClose}
            okButtonProps={{
                loading
            }}
            className='modal-copy'
            cancelButtonProps={{
                hidden: finished,
                style: finished ? { display: 'none' } : undefined
            }}
            onOk={finished ? handleClose : handleCopy}
            okText={okText}
            title={`Copiar preguntas ${loading ? '' : `(${(questions ?? []).length})`} de ${topicToCopy?.name} a ${topicName}`}
        >
            {(!questions || questions.length === 0) && (
                <div>
                    <p>{`No se han encontrado preguntas del tema ${topicToCopy?.name} `}</p>
                </div>
            )}
            {(questions && questions.length > 0
                ? (
                    questions.map((question: IQuestion) => (
                        <div key={question._id} className='question-card'>
                            {(copying && (!(fullfilledIds.includes(question._id!)) && !(errorIds.includes(question._id!))))
                                ? (
                                    <Spin size="small" />
                                )
                                : null}
                            {fullfilledIds.includes(question._id!) && (
                                <div className='icon-progress'>
                                    <IconCheck color='green' size={26} />
                                </div>
                            )}
                            {((!fullfilledIds.includes(question._id!)) && errorIds.includes(question._id!)) && (
                                <div className='icon-progress'>
                                    <IconCross color="red" size={26} />
                                </div>
                            )}
                            <p>{question.title}</p>
                        </div>
                    ))
                )
                : null)}
        </Modal>
    )
}

export { CopyProgressModal }
