import { Modal, Spin, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { getColumns } from './constants'
import { Screen, Select } from '../../components'
import { useCallback, useMemo, useState } from 'react'
import { TopicsModal } from './components/TopicsModal'
import { useGetSubjectsByBranch } from '../../services/subject'
import { useGetTopics, useGetTopicsBySubject, useRemoveTopic } from '../../services/topic'
import { type ITopic } from '../../types/topic'
import { useGetQuestionsInfo } from '../../services/question/utils'
import { useMassiveAlloweance } from '../../services/userAlloweance'
import { useGetBranches } from '../../services/branch'
import { parseSelect } from '../../utils/parseSelect'
import { useCopyQuestions } from '../../services/question'
import { CopyProgressModal } from './components/CopyProgressModal'
import { sortByKey } from '../../utils/sortByKey'

const AdminTopics = () => {
  const { branches, loading: loadingBranches } = useGetBranches()
  const { subjects } = useGetSubjectsByBranch()
  const { topics, loading, refetch } = useGetTopics()
  const { copyQuestions, loading: copying } = useCopyQuestions()

  const { removeTopic, loading: removeLoading } = useRemoveTopic()
  const { questionsInfo, loading: loadingInfo, refetch: refetchInfo } = useGetQuestionsInfo()
  const { massiveAlloweance, loading: loadingMassive } = useMassiveAlloweance()

  const [modal, setModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [massiveModal, setMassiveModal] = useState(false)
  const [copyModal, setCopyModal] = useState(false)
  const [copyProgressModal, setCopyProgressModal] = useState(false)

  const [selectedTopic, setSelectedTopic] = useState<ITopic | undefined>()

  // Form fields
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>()
  const [selectedSubject, setSelectedSubject] = useState<string | undefined>()
  const [selectedFormTopic, setSelectedFormTopic] = useState<ITopic | undefined>()

  const { subjects: branchSubjects, loading: loadingBranchSubjects } = useGetSubjectsByBranch(selectedBranch)
  const { topics: subjectTopics, loading: loadingSubjectTopics } = useGetTopicsBySubject(selectedSubject)

  const toggleCopyModal = useCallback(() => { setCopyModal((prev) => !prev) }, [])
  const toggleModal = useCallback(() => { setModal((prev) => !prev) }, [])
  const toggleMassiveModal = useCallback(() => { setMassiveModal((prev) => !prev) }, [])
  const toggleRemoveModal = useCallback(() => { setRemoveModal((prev) => !prev) }, [])

  const parsedBranches = useMemo(() => parseSelect({
    array: (branches || []),
    labelKey: 'name',
    valueKey: '_id'
  }), [branches])

  const parsedSubjects = useMemo(() => parseSelect({
    array: (branchSubjects || []),
    labelKey: 'name',
    valueKey: '_id'
  }), [branchSubjects])

  const parsedTopics = useMemo(() => parseSelect({
    array: (subjectTopics || []),
    labelKey: 'name',
    valueKey: '_id'
  }), [subjectTopics])

  const onClose = async () => {
    setSelectedTopic(undefined)
    setModal(false)
    setRemoveModal(false)
    await refetch()
  }

  const onCloseRemove = () => {
    setSelectedTopic(undefined)
    toggleRemoveModal()
  }
  const onCloseMassive = () => {
    setSelectedTopic(undefined)
    toggleMassiveModal()
  }
  const onCloseShare = () => {
    setSelectedTopic(undefined)
    toggleCopyModal()
  }

  const onEditTopic = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleModal()
  }

  const onMassive = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleMassiveModal()
  }

  const onCopy = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleCopyModal()
  }

  const onRemoveModal = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleRemoveModal()
  }

  const handleRemove = async () => {
    if (selectedTopic?._id) {
      await removeTopic(selectedTopic?._id)
      await onClose()
    }
  }

  const handleMassive = async () => {
    if (selectedTopic?._id) {
      await massiveAlloweance(selectedTopic?._id)
      toggleMassiveModal()
    }
  }

  const handleCopy = async () => {
    if (selectedTopic?._id && selectedBranch) {
      if (selectedTopic?._id && selectedFormTopic) {
        await copyQuestions({
          from: selectedFormTopic._id!,
          to: selectedTopic._id
        })
      }
      await refetchInfo()
    }
  }

  const handleClose = () => {
    setCopyProgressModal(false)
    setSelectedBranch(undefined)
    setSelectedSubject(undefined)
    setSelectedTopic(undefined)
    setSelectedFormTopic(undefined)
  }

  const handleContinueCopy = () => {
    setCopyModal(false)
    setTimeout(() => {
      setCopyProgressModal(true)
    }, 500)
  }

  const columns = getColumns({
    onEdit: onEditTopic,
    onRemove: onRemoveModal,
    onMassive,
    onCopy,
    info: questionsInfo
  })

  const onSelectTopic = (topic: string) => {
    const selected = subjectTopics.find((t: ITopic) => t._id === topic)
    setSelectedFormTopic(selected)
  }

  return (
    <Screen title='Temas' id="topics" actions={[{ icon: <PlusOutlined />, onClick: toggleModal }]}>
      {(loading || loadingInfo) && <Spin tip="Loading" size="large" />}
      {!(loading || loadingInfo) && (
        <Table locale={{ emptyText: 'No hay temas que mostrar' }} columns={columns} dataSource={topics} rowKey="_id" />
      )}
      <TopicsModal
        selectedTopic={selectedTopic}
        subjects={subjects ?? []}
        open={modal}
        onClose={onClose}
        title={`${selectedTopic ? 'Actualizar' : 'Crear'} Tema`}
      />
      <CopyProgressModal
        visible={copyProgressModal}
        topicName={selectedTopic?.name || ''}
        topicToCopy={selectedFormTopic}
        onClose={handleClose}
        onCopy={handleCopy}
      />
      <Modal
        open={removeModal}
        onCancel={onCloseRemove}
        okButtonProps={{
          danger: true,
          loading: removeLoading
        }}
        onOk={handleRemove}
        okText="Eliminar tema"
        title="Eliminar tema"
      >
        {`Vas a eliminar el tema "${selectedTopic?.name}". ¿Estas seguro de que quieres continuar? No podrás acceder al tema, ni a sus preguntas, etc...`}
      </Modal>
      <Modal
        open={massiveModal}
        onCancel={onCloseMassive}
        okButtonProps={{
          loading: loadingMassive
        }}
        onOk={handleMassive}
        okText="Continuar"
        title="Matriculación massiva"
      >
        {`Vas a matricular a todos los usuarios al tema "${selectedTopic?.name}". ¿Estas seguro de que quieres continuar?`}
      </Modal>
      <Modal
        open={copyModal}
        onCancel={onCloseShare}
        okButtonProps={{
          loading: copying,
          disabled: !selectedTopic || !selectedFormTopic || !selectedBranch || !selectedSubject
        }}
        onOk={handleContinueCopy}
        okText="Continuar"
        title="Importar preguntas desde otro tema"
      >
        {'Selecciona el tema al que quieres copiar las preguntas:'}
        <Select
          loading={loadingBranches}
          placeholder="Rama"
          onChange={setSelectedBranch}
          options={parsedBranches}
          value={selectedBranch}
        />
        {selectedBranch && (
          <Select
            loading={loadingBranchSubjects}
            placeholder="Bloque"
            onChange={setSelectedSubject}
            options={parsedSubjects}
            value={selectedSubject}
          />
        )}
        {selectedBranch && selectedSubject && (
          <Select
            loading={loadingSubjectTopics}
            placeholder="Tema"
            onChange={onSelectTopic}
            options={sortByKey((parsedTopics ?? []), 'label')}
            value={selectedFormTopic?._id}
          />
        )}
      </Modal>
    </Screen>
  )
}

export { AdminTopics }
