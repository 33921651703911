import { gql } from '@apollo/client'

export const GET_QUESTIONS = gql`
query getQuestionsByBranch ($input: String!){
  getQuestionsByBranch (input: $input){
      _id
      title
      image
      answers
      correctAnswer
      pastexam
      info
      topic {
        name
        _id
        color
      }
      subject {
        name
        _id
        color
      }
      branch {
        name
        _id
      }
      rating
    }
  }
`

export const GET_FILTERED_QUESTIONS = gql`
query getQuestionsForTest ($input: GetQuestionsForTest!) {
  getQuestionsForTest(input:$input) {
    _id
    title
    image
    answers
    correctAnswer
    pastexam
    info
    topic {
      name
      _id
      color
    }
    subject {
      name
      _id
      color
    }
    branch {
      name
      _id
    }
    rating
  }
}
`
export const GET_QUESTIONS_PREPARED_TEST = gql`
query getQuestionsForPreparedTest ($input: GetQuestionsForPreparedTest!) {
  getQuestionsForPreparedTest(input:$input) {
    _id
    title
    image
    answers
    correctAnswer
    pastexam
    info
    topic {
      name
      _id
      color
    }
    subject {
      name
      _id
      color
    }
    branch {
      name
      _id
    }
    rating
  }
}
`

export const GET_QUESTIONS_BY_TOPIC = gql`
  query getQuestionsByTopic($input: String!) {
    getQuestionsByTopic(input: $input) {
      _id
      title
      image
      answers
      correctAnswer
      pastexam
      info
      topic {
        name
        _id
        color
      }
      subject {
        name
        _id
        color
      }
      branch {
        name
        _id
      }
      rating
      }
  }
`
