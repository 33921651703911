import { useGetQuestions } from '.'
import { type ITopic } from '../../types/topic'

export const useGetQuestionsInfo = () => {
  const { loading, questions, refetch } = useGetQuestions()

  const questionsInfo = (questions || []).reduce((acc: any, item) => {
    if (!acc[(item.topic as ITopic).name!]) {
      acc[(item.topic as ITopic).name!] = []
    }

    acc[(item.topic as ITopic).name!].push(item)

    return acc
  }, {})

  return { loading, questionsInfo, refetch }
}
