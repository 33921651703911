import { useEffect, useState } from 'react'
import io, { type Socket } from 'socket.io-client'
import { BACKEND_URL } from '../constants/apollo'

export const useSocket = () => {
    const [socket, setSocket] = useState<Socket | null>(null)

    useEffect(() => {
        const socketIo = io(BACKEND_URL, {
            reconnection: true,
            upgrade: true,
            transports: ['websocket', 'polling'],
            path: '/socket.io'
        })

        setSocket(socketIo)

        return () => {
            socketIo.disconnect()
        }
    }, [])

    return socket
}
